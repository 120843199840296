import { QueryFunction, UseQueryOptions } from "react-query";
import { get } from "src/api";
import { focusPoster, focusListItems } from "src/types";

const getList: [string, QueryFunction<focusListItems>] = [
  "listPoster",
  () => get<focusListItems>("/poster"),
];
const getSharedList: [string, QueryFunction<focusListItems>] = [
  "sharedListPoster",
  () => get<focusListItems>("/shared/details?entityName=poster"),
];
// getSharedList
const getOne = (id: number) =>
  [
    ["poster", id] as [string, number],
    () => get<focusPoster>(`/poster/${id}`),
    {} as UseQueryOptions<focusPoster, Error, focusPoster, [string, number]>,
  ] as [
    [string, number],
    QueryFunction<focusPoster>,
    UseQueryOptions<focusPoster, Error, focusPoster, [string, number]>
  ];

export { getList, getOne, getSharedList };
