import React, { useState, useEffect } from "react";
import BaseView from "../BaseView";
import { Box, HStack, Text, Flex } from "@chakra-ui/react";
import { get } from "src/api/index";
import useLocale from "src/providers/useLocale";
import { Manifestation } from "src/types";
import { FormattedMessage } from "react-intl";
import { FaPlayCircle, FaStopCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "src/store";
import { playMedia, stopMedia, pauseMedia } from "src/store/media";
import { FaCirclePause } from "react-icons/fa6";
import { useHistory } from "react-router-dom";

const ManifestationGate = () => {
  //current locale
  const [locale] = useLocale();

  //data to display
  const [data, setData] = useState<Array<Manifestation>>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  //background media play function
  const dispatch = useDispatch();
  const { id, isPlaying, isPaused } = useSelector((state) => ({
    id: state.media.id,
    isPlaying: state.media.playing,
    isPaused: state.media.paused,
  }));

  const closeMedia = () => dispatch(stopMedia());
  const history = useHistory();

  //fetch audios upon mounting component
  useEffect(() => {
    const fetchData = async () => {
      const response = await get<Array<Manifestation>>(
        "/manifestation",
        locale === "ar" || locale === "en" ? locale : undefined
      );
      setLoading(false);
      if (response && Array.isArray(response)) {
        setData(response);
      } else setError("Failed to fetch data!");
    };
    fetchData();
  }, [locale]);

  //function to pause media
  const play = (source: string, id: number) => {
    dispatch(
      playMedia({
        source: source,
        name: "manifestation",
        type: "audio",
        id: `manifestation-${id}`,
        loop: false,
        baBtn: true,
        pageUrl: history.location.pathname,
      })
    );
  };

  //function to pause media
  const pause = (source: string, id: number) => {
    dispatch(
      pauseMedia({
        id: `manifestation-${id}`,
        source: source,
        type: "audio",
        loop: false,
        name: "manifestation",
        baBtn: false,
        pageUrl: "",
      })
    );
  };

  return (
    <BaseView>
      <HStack
        paddingBottom={"50px"}
        display={"flex"}
        flexDir={"column"}
        alignItems="flex-start"
        paddingTop={"30px"}
      >
        {loading || error ? (
          <div style={{ padding: "30px", textAlign: "center" }}>
            {loading && "Loading..."}
            {error && error}
          </div>
        ) : (
          <>
            <Box
              display={"flex"}
              flexDir="column"
              alignItems="center"
              width={"100%"}
              maxWidth={"1200px"}
              paddingTop={"30px"}
              dir="auto"
            >
              {data.map(
                (item) =>
                  item.name &&
                  item.description && (
                    <Box
                      width={{ base: "90%", md: "100%" }}
                      borderRadius={"10px"}
                      background={"#fcfafa"}
                      padding={"15px"}
                      display={"flex"}
                      flexDir="column"
                      alignItems={"flex-start"}
                      justifyContent="space-between"
                      margin={"10px 0"}
                      boxShadow={"-3px 3px 5px silver"}
                      border="2px solid transparent"
                      key={item.id!}
                      minHeight="200px"
                    >
                      <Box>
                        <Text
                          fontSize={"14px"}
                          color="#c4c0c0"
                          fontWeight="bold"
                        >
                          {item.name}
                        </Text>
                        <Text color="grey" paddingTop={"10px"}>
                          {item.description}
                        </Text>
                      </Box>
                      <Box
                        width={"100%"}
                        display="flex"
                        justifyContent="center"
                      >
                        <Box
                          // as="button"
                          // background={"#2895ff"}
                          minWidth={{ base: "100px", xl: "150px" }}
                          // padding={"7px"}
                          // borderRadius="5px"
                          display={"flex"}
                          justifyContent="center"
                          alignItems="center"
                          // transition={"250ms ease-out"}
                          // _hover={{ background: "#9fb9e0" }}
                          // onClick={() => handleClick(item.mediaUrl!, item.id!)}
                        >
                          {id === `manifestation-${item.id}` &&
                          (isPlaying || isPaused) ? (
                            <Flex gap={2}>
                              {isPaused && (
                                <Flex
                                  as="button"
                                  background={"#2895ff"}
                                  padding={"7px"}
                                  borderRadius="5px"
                                  justifyContent="center"
                                  alignItems="center"
                                  _hover={{ background: "#9fb9e0" }}
                                  onClick={() => play(item.mediaUrl!, item.id!)}
                                >
                                  <FaPlayCircle
                                    size={18}
                                    color="white"
                                    cursor="pointer"
                                  />
                                  <Text color="white" margin={"0 5px"}>
                                    <FormattedMessage id="play" />
                                  </Text>
                                </Flex>
                              )}
                              {isPlaying && (
                                <Flex
                                  as="button"
                                  background={"#2895ff"}
                                  padding={"7px"}
                                  borderRadius="5px"
                                  justifyContent="center"
                                  alignItems="center"
                                  _hover={{ background: "#9fb9e0" }}
                                  onClick={() =>
                                    pause(item.mediaUrl!, item.id!)
                                  }
                                >
                                  <FaCirclePause size={18} color="white" />
                                  <Text color="white" margin={"0 5px"}>
                                    <FormattedMessage id="pause" />
                                  </Text>
                                </Flex>
                              )}
                              <Flex
                                as="button"
                                background={"#2895ff"}
                                padding={"7px"}
                                borderRadius="5px"
                                justifyContent="center"
                                alignItems="center"
                                _hover={{ background: "#9fb9e0" }}
                                onClick={() => closeMedia()}
                              >
                                <FaStopCircle size={18} color="white" />
                                <Text color="white" margin={"0 5px"}>
                                  <FormattedMessage id="stop" />
                                </Text>
                              </Flex>
                            </Flex>
                          ) : (
                            <Flex
                              as="button"
                              background={"#2895ff"}
                              padding={"7px"}
                              borderRadius="5px"
                              justifyContent="center"
                              alignItems="center"
                              _hover={{ background: "#9fb9e0" }}
                              onClick={() => play(item.mediaUrl!, item.id!)}
                            >
                              <FaPlayCircle
                                size={18}
                                color="white"
                                cursor="pointer"
                              />
                              <Text color="white" margin={"0 5px"}>
                                <FormattedMessage id="play" />
                              </Text>
                            </Flex>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )
              )}
            </Box>
          </>
        )}
      </HStack>
    </BaseView>
  );
};

export default ManifestationGate;
