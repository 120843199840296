import React from "react";
import { Box, Text } from "@chakra-ui/react";

type LightGemProps = {
  text: string;
  color?: string;
  textColor?: string;
  onClick?: () => void;
  isPointer?: boolean;
};

const LightGem: React.FC<LightGemProps> = ({
  text,
  color,
  textColor,
  onClick,
  isPointer,
}) => {
  return (
    <Box
      bg={color}
      borderRadius="5px"
      w="32px"
      h="32px"
      alignItems="center"
      textAlign="center"
      onClick={onClick}
      cursor={isPointer ? "pointer" : "default"}
    >
      <Text verticalAlign="center" my="auto" color={textColor} lineHeight="2">
        {text}
      </Text>
    </Box>
  );
};

export default LightGem;
