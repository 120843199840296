import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  VStack,
  Text,
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Spinner,
  AbsoluteCenter,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
import React from "react";
import { PureToneData } from "src/types";
import { getPureTones } from "src/api/sal-magic/query";
import { useQuery } from "react-query";
import { PURE_TONES_ROUTE, FREQUENCY_ROUTE, WAVES_ROUTE } from "src/routes";
import { FormattedMessage } from "react-intl";
import { Link as ReactRouterLink } from "react-router-dom";
import SalMagicGate from "./components/SalMagicGate";
import SalMagicAudioPlayer from "./components/SalMagicPlayer/SalMagicAudioPlayer";

const PureTones = React.memo(() => {
  const {
    data: pureTonesData,
    isLoading: isPureTonesLoading,
    error,
    refetch: refetchPureTones,
  } = useQuery<PureToneData, Error>(
    ["listPureTones", localStorage.getItem("locale")],
    getPureTones,
    {
      enabled: true,
      retry: 3,
      retryDelay: 1000,
      refetchOnWindowFocus: false,
    }
  );

  if (isPureTonesLoading) {
    return (
      <AbsoluteCenter>
        <Spinner aria-label="loading..." size="xl" />
      </AbsoluteCenter>
    );
  }

  if (error) {
    return (
      <Box my={10}>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Error loading data</AlertTitle>
          <AlertDescription>
            <FormattedMessage
              id="dataLoadError"
              defaultMessage="Unable to load the data. Please try again later."
            />
          </AlertDescription>
          <CloseButton position="absolute" right="8px" top="8px" />
        </Alert>
      </Box>
    );
  }

  return (
    <Box my={10}>
      <ChakraBreadcrumb
        color="gray.900"
        textTransform="capitalize"
        separator=" "
        mb={10}
      >
        <BreadcrumbItem w="100%">
          <>
            <BreadcrumbLink as={ReactRouterLink} to={FREQUENCY_ROUTE}>
              <FormattedMessage id="frequency" />
            </BreadcrumbLink>
            &nbsp;-&nbsp;
            <BreadcrumbLink as={ReactRouterLink} to={WAVES_ROUTE}>
              <FormattedMessage id="waves" />
            </BreadcrumbLink>
            &nbsp;-&nbsp;
            <BreadcrumbLink as={ReactRouterLink} to={PURE_TONES_ROUTE}>
              <FormattedMessage id="Pure Tones" />
            </BreadcrumbLink>
          </>
        </BreadcrumbItem>

        <BreadcrumbItem
          textStyle="h2"
          isCurrentPage
          w="100%"
          justifyContent="space-between"
          alignItems="start"
        >
          <Heading as="h2" textStyle={"h2"}>
            <FormattedMessage id="Pure Tones" />
          </Heading>
          <SalMagicGate radius={105} />
        </BreadcrumbItem>
      </ChakraBreadcrumb>
      <VStack
        display={"flex"}
        wrap={"wrap"}
        margin={"auto"}
        width="100%"
        flexDir="row"
        justifyContent="center"
        alignItems="center"
      >
        <Accordion key="pure_tone_cats" w="100%" allowMultiple>
          {pureTonesData
            ?.sort((a, b) => a.id - b.id)
            .map((category) => (
              <span id={`category-${category.id}`}>
                <AccordionItem
                  key={`item-${category.id}`}
                  id={`category-${category.id}`}
                >
                  <h2>
                    <AccordionButton key={`button-${category.id}`}>
                      <Text flex="1" textAlign="left" fontWeight="bold">
                        {category.name}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} px={10}>
                    <VStack width="100%" spacing={6}>
                      {category.pure_tones
                        .sort((a, b) => a.id - b.id)
                        .map((pure_tone, index) => (
                          <VStack
                            key={`${index}:vstack-pure-tone${pure_tone.id}`}
                            width="100%"
                          >
                            <SalMagicAudioPlayer
                              key={`${index}:pure-tone-${pure_tone.id}`}
                              entityName={"pure_tones"}
                              trackUrl={pure_tone.url}
                              trackName={pure_tone.name}
                              trackCategory={category.name}
                              trackInfo={pure_tone.description}
                              trackId={pure_tone.id}
                              trackCover={pure_tone.imageUrl}
                              trackRating={pure_tone.rating}
                              currentUserRating={pure_tone.myRating}
                              refetchTracks={refetchPureTones}
                              categoryId={category.id}
                            />
                            {index < category.pure_tones.length - 1 && (
                              <hr
                                style={{
                                  margin: "36px",
                                  height: "2px",
                                  width: "80%",
                                }}
                              />
                            )}
                          </VStack>
                        ))}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              </span>
            ))}
        </Accordion>
      </VStack>
    </Box>
  );
});

export default PureTones;
