const MEDIA_URL = "https://saladmin.smartsway.com:3000";

const templatesLive = [
  {
    id: 1406,
    name: "poster 0 ar",
    title: "أنوي أن أطبق قواعد الوفرة لتجليها في حياتي",
    nameSize: "24px",
    nameColor: "#fff",
    contentSize: "16px",
    contentColor: "#fff",
    imageUrl: null,
    coverUrl: `${MEDIA_URL}/media/1650953679125-716318118.jpeg`,
    goals: [],
    thumbnailImageUrl: "media/1649785204060-269127333.jpeg",
  },
  {
    id: 1407,
    name: "poster 0 en",
    title: "I manifest abundance into my life",
    nameSize: "24px",
    nameColor: "#fff",
    contentSize: "16px",
    contentColor: "#fff",
    imageUrl: null,
    coverUrl: `${MEDIA_URL}/media/1650953679125-716318118.jpeg`, // with text
    goals: [],
    thumbnailImageUrl: "media/1649785243131-319503539.jpeg",
  },
  {
    id: 1408,
    name: "poster 1 ar",
    title: "أخطط بدقة لنجاح مشروعي أو عملي",
    nameSize: "24px",
    nameColor: "#fff",
    contentSize: "16px",
    contentColor: "#fff",
    imageUrl: null,
    coverUrl: `${MEDIA_URL}/media/1650952940819-578867464.jpeg`,
    goals: [],
    thumbnailImageUrl: "media/1649785307413-726685844.jpeg",
  },
  {
    id: 1409,
    name: "poster 1 en",
    title: "I plan effictively to achieve success",
    nameSize: "24px",
    nameColor: "#fff",
    contentSize: "16px",
    contentColor: "#fff",
    imageUrl: null,
    coverUrl: `${MEDIA_URL}/media/1650952940819-578867464.jpeg`,
    goals: [],
    thumbnailImageUrl: "media/1649785402959-526730946.jpeg",
  },
  {
    id: 1411,
    name: "psoter 2 ar",
    title: "أنوي أن أجذب علاقات صحية و إيجابية",
    nameSize: "24px",
    nameColor: "#fff",
    contentSize: "16px",
    contentColor: "#fff",
    imageUrl: null,
    coverUrl: `${MEDIA_URL}/media/1650952469223-39474800.jpeg`,
    goals: [],
    thumbnailImageUrl: "media/1649785632265-543288913.jpeg",
  },
  {
    id: 1412,
    name: "poster 2 en",
    title: "I attract healty and positive relationships",
    nameSize: "24px",
    nameColor: "#fff",
    contentSize: "16px",
    contentColor: "#fff",
    imageUrl: null,
    coverUrl: `${MEDIA_URL}/media/1650952469223-39474800.jpeg`,
    goals: [],
    thumbnailImageUrl: "media/1649785677472-359131019.jpeg",
  },
];

const templatesTest = [
  {
    id: 19,
    name: "ar 0",
    title: "أنوي أن أطبق قواعد الوفرة لتجليها في حياتي",
    nameSize: "24px",
    nameColor: "#fff",
    contentSize: "16px",
    contentColor: "#fff",
    imageUrl: null,
    coverUrl: `${MEDIA_URL}/media/1650789726682-447613750.jpeg`,
    goals: [],
    thumbnailImageUrl: "media/1649839424263-826929562.jpeg",
  },
  {
    id: 20,
    name: "en 0",
    title: "I manifest abundance into my life",
    nameSize: "24px",
    nameColor: "#fff",
    contentSize: "16px",
    contentColor: "#fff",
    imageUrl: null,
    coverUrl: `${MEDIA_URL}/media/1650789726682-447613750.jpeg`,
    goals: [],
    thumbnailImageUrl: "media/1649839490038-615686796.jpeg",
  },
  {
    id: 21,
    name: "ar 1\\",
    title: "أخطط بدقة لنجاح مشروعي أو عملي",
    nameSize: "24px",
    nameColor: "#fff",
    contentSize: "16px",
    contentColor: "#fff",
    imageUrl: null,
    coverUrl: `${MEDIA_URL}/media/1650789881938-926414940.jpeg`,
    goals: [],
    thumbnailImageUrl: "media/1649839538190-909805376.jpeg",
  },
  {
    id: 22,
    name: "en 1",
    title: "I plan effictively to achieve success",
    nameSize: "24px",
    nameColor: "#fff",
    contentSize: "16px",
    contentColor: "#fff",
    imageUrl: null,
    coverUrl: `${MEDIA_URL}/media/1650789881938-926414940.jpeg`,
    goals: [],
    thumbnailImageUrl: "media/1649839568166-551414009.jpeg",
  },
  {
    id: 23,
    name: "ar 2",
    title: "أنوي أن أجذب علاقات صحية و إيجابية",
    nameSize: "24px",
    nameColor: "#fff",
    contentSize: "16px",
    contentColor: "#fff",
    imageUrl: null,
    coverUrl: `${MEDIA_URL}/media/1650789546262-349747452.jpeg`,
    goals: [],
    thumbnailImageUrl: "media/1649839593896-247581047.jpeg",
  },
  {
    id: 24,
    name: "en 2",
    title: "I attract healty and positive relationships",
    nameSize: "24px",
    nameColor: "#fff",
    contentSize: "16px",
    contentColor: "#fff",
    imageUrl: null,
    coverUrl: `${MEDIA_URL}/media/1650789546262-349747452.jpeg`,
    goals: [],
    thumbnailImageUrl: "media/1649839665158-604216833.jpeg",
  },
];

export const currentEnvTemplates =
  process.env.REACT_APP_ENV === "development" ? templatesTest : templatesLive;
