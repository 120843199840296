//ToDo: Reftactor the comonent and creat a general intentions separate component for it.
//ToDo: API integration.
import { Box, VStack, HStack, Text, useDisclosure } from "@chakra-ui/react";
import React, { useState, useMemo } from "react";
import BaseView from "src/views/Prepration/BaseView";
import { affirmationBg } from "src/assets/icons";
import SpecialIntentions from "./SpecialIntentions";
import Link from "src/components/Link/Link";
import { FormattedMessage, useIntl } from "react-intl";
import StoppedIntentionModel from "./StoppedIntentionModel";
import GeneralIntentions from "./GeneralIntentions";
import { timeoutCollection } from "time-events-manager";
import { useSelector, useDispatch } from "src/store";
import {
  toggleIsBtn,
  endDateValue,
  toggleBlinkValue,
  setMessage,
  startDateValue,
} from "src/store/media";
import SharingModal from "src/components/SharingModal/SharingModal";
import { useHistory } from "react-router-dom";

const Intention = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Timer State
  const [minutes, setMinutes] = useState<number>();
  const [seconds, setSeconds] = useState<number>();
  const [hours, setHours] = useState<number>();
  const [days, setDays] = useState<number>();
  // interval
  const [currentInterval, setCurrentInterval] = useState<any>();
  const [disableSendIntentionBtn, setDisableSendIntentionBtn] =
    useState<boolean>(true);
  const node = React.useRef<HTMLDivElement>(null);
  const { endDateString, startDateString, messageValue, blink } = useSelector(
    (state) => ({
      endDateString: state.media.endDate,
      blink: state.media.blink,
      messageValue: state.media.messageValue,
      startDateString: state.media.startDate,
    })
  );
  const startDate = useMemo(() => new Date(startDateString), [startDateString]);
  const endDate = useMemo(() => new Date(endDateString), [endDateString]);
  const intl = useIntl();
  const history = useHistory();
  const pageUrl = history.location.pathname;

  const handleClick = (e: any) => {
    const addInput = document.getElementById("intentions");
    if (e.target instanceof HTMLButtonElement) {
      return;
    }
    if (e.target === addInput) {
      return setDisableSendIntentionBtn(true);
    }
    if (e.target instanceof HTMLInputElement) {
      return setDisableSendIntentionBtn(false);
    }
    //  else{setDisableSendIntentionBtn(true); setMessage&&setMessage(undefined)}
  };

  // @ts-ignore
  React.useEffect(() => {
    // auth.validateToken();
    // if we have intention already works
    if (window.localStorage.getItem("intentionStartDate")) {
      // get the message from local storage
      const currentMessage = window.localStorage.getItem("intentionMessage");
      // get the date form local storage and store it as date object
      const startDateFromlocalStorage = new Date(
        window.localStorage.getItem("intentionStartDate")!
      );
      // set values we fetched from the localStorage
      dispatch(setMessage({ value: currentMessage || messageValue }));
      dispatch(startDateValue({ value: String(startDateFromlocalStorage) }));

      var intentionTimeInterval = setInterval(() => {
        const now = new Date();
        const timeDifference = now.getTime() - startDate!.getTime();
        setSeconds(Math.floor((timeDifference % (1000 * 60)) / 1000));
        setMinutes(
          Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
        );
        setHours(
          Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          )
        );
        setDays(Math.floor(timeDifference / (1000 * 3600 * 24)));
      }, 1000);
      setCurrentInterval(intentionTimeInterval);
      if (messageValue && !blink) {
        dispatch(toggleIsBtn({ value: true }));
        return dispatch(toggleBlinkValue({}));
      }
    }
    messageValue && blink && setDisableSendIntentionBtn(false);
    document.addEventListener("click", handleClick);
    return () => {
      window.clearInterval(intentionTimeInterval!);
      timeoutCollection.removeAll();
      document.removeEventListener("click", handleClick);
    };
  }, [messageValue, blink, dispatch, startDate]);

  const handleLinkBlink = () => {
    if (messageValue && !blink) {
      dispatch(toggleIsBtn({ value: true }));
      dispatch(startDateValue({ value: String(new Date()) }));
      return dispatch(toggleBlinkValue({}));
    }
    dispatch(endDateValue({ value: String(new Date()) }));
    setSeconds(0);
    setMinutes(0);
    setHours(0);
    setDays(0);
    window.clearInterval(currentInterval);
    timeoutCollection.removeAll();
    dispatch(toggleIsBtn({ value: false }));
    window.localStorage.removeItem("intentionMessage");
    blink ? onOpen() : dispatch(setMessage({ value: undefined }));
    return dispatch(toggleBlinkValue({}));
  };

  const handleCloseModal = () => {
    dispatch(endDateValue({ value: String(new Date()) }));
    onClose();
    dispatch(setMessage({ value: undefined }));
    setDisableSendIntentionBtn(true);
  };
  const handleMessage = (e: any) => {
    dispatch(setMessage({ value: e.target.value }));
    !blink && window.localStorage.setItem("intentionMessage", e.target.value);
    setDisableSendIntentionBtn(false);
  };
  const {
    isOpen: isSharingModalOpen,
    onClose: onSharingModalClose,
    onOpen: onSharingModalOpen,
  } = useDisclosure();
  const [currentList, setCurrentList] = useState<any>({ id: 0, name: "" });
  const onShare: any = (item) => {
    onSharingModalOpen();
    setCurrentList(item);
  };
  return (
    <Box id={"intentions-container"}>
      <BaseView imageSrc={affirmationBg} />
      <Box
        display="flex"
        flexDir="column"
        alignItems="center"
        width="100%"
        paddingTop="50px"
      >
        <HStack
          flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
          justify={["center", "center", "center", "space-between"]}
          maxWidth={"720px"}
          alignItems={"flex-start"}
          me={["10px", "-54px", "-10px"]}
        >
          <div ref={node}>
            <SpecialIntentions
              share={onShare}
              onClick={handleMessage}
              name={blink ? "" : messageValue}
            />
          </div>
          <div ref={node}>
            <GeneralIntentions onClick={handleMessage} />
          </div>
        </HStack>

        <VStack mb={".3rem"} spacing={0}>
          {/*/css for this is in app.css!*/}
          <Text
            className={blink ? "blink_intention" : "normal_intention"}
            textAlign={"center"}
            mt={"16px"}
            textStyle={"intentionDisplayText"}
          >
            {messageValue}
          </Text>
          <HStack>
            <Text textStyle={"intentionModalTime"}>
              {intl.formatMessage({ id: "day" })} ({days})
            </Text>
            <Text textStyle={"intentionModalTime"}>
              {intl.formatMessage({ id: "hour" })} ({hours})
            </Text>
            <Text textStyle={"intentionModalTime"}>
              {intl.formatMessage({ id: "minute" })} ({minutes})
            </Text>
            <Text textStyle={"intentionModalTime"}>
              {intl.formatMessage({ id: "second" })} ({seconds}){" "}
            </Text>
          </HStack>
          <div>
            <Link
              id={"sendInentionBtn"}
              disabled={disableSendIntentionBtn && !blink}
              onClick={handleLinkBlink}
              asBtn={true}
              my={4}
            >
              {blink ? (
                <FormattedMessage id="stop" defaultMessage={"إيقاف"} />
              ) : (
                <FormattedMessage id="send" defaultMessage={"إرسال"} />
              )}
            </Link>
          </div>
        </VStack>
        <StoppedIntentionModel
          startDate={startDate}
          endDate={endDate && endDate}
          isOpen={isOpen}
          onClose={handleCloseModal}
          title={messageValue}
          content={
            <FormattedMessage
              id="stoppedintention"
              defaultMessage={"تم إيقاف النية المرسله"}
            />
          }
        />
      </Box>
      <SharingModal
        isOpen={isSharingModalOpen}
        onClose={onSharingModalClose}
        sharedEntity={{ name: "intention", id: currentList.id }}
        showOptions={false}
      />
    </Box>
  );
};

export default Intention;
