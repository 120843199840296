import React, { useRef, useMemo, useEffect, useCallback } from "react";
import { MediaPlayerType } from "./MediaPlayer.types";
import ReactPlayer from "react-player";
import {
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  HStack,
  Flex,
} from "@chakra-ui/react";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
// @ts-ignore
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import iOS from "src/helpers/iOSDetector";
import debounce from "lodash.debounce";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "src/store";
import {
  stopMedia,
  togglePlay as playToggler,
  playNextTrack,
  playPreviousTrack,
  setVolume as setVol,
} from "src/store/media";
import getReadableTime from "src/helpers/getReadableTime";
import usePlaylists from "src/views/Playlists/usePlaylists";

const times = ["0:00", "0:01", "0:02"];

const MediaPlayer = ({
  src,
  loop = false,
  disableSeeking = false,
  muted = false,
  styles = {},
  noControls = false,
  isMaximized,
}: MediaPlayerType) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const togglePlay = useCallback(
    (v?: boolean) => dispatch(playToggler({ value: v ?? undefined })),
    [dispatch]
  );
  const closeMedia = () => dispatch(stopMedia());
  const setVolume = useCallback(
    (value) => dispatch(setVol({ volume: value })),
    [dispatch]
  );
  const isPlay = useSelector((state) => state.media.playing);
  const {
    playlistsQuery: { data: playlists },
  } = usePlaylists();
  const isPlaylist = useSelector((state) => state.media.isPlaylist);
  const volume = useSelector((state) => state.media.volume);
  // const [volume, setVolume] = React.useState(muted ? 0 : 1);
  const type: "audio" | "video" =
    src?.endsWith(".mp3") || src?.endsWith(".ogg") ? "audio" : "video";
  const ref = useRef<ReactPlayer>(null);
  const handle = useFullScreenHandle();
  const [progress, setProgress] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  const debouncedSeek = useMemo(
    () =>
      debounce((time: number) => {
        if (!times.includes(getReadableTime(time))) {
          if (iOS()) {
            togglePlay && togglePlay(false);
          }
          alert(
            formatMessage({
              id: "seeking",
            })
          );
          ref.current?.seekTo(1);
        }
      }, 1000),
    [formatMessage, togglePlay]
  );
  useEffect(() => {
    if (muted) {
      setVolume(0);
    }
    return () => {
      debouncedSeek.cancel();
    };
  }, [debouncedSeek, muted, setVolume]);
  return (
    <div
      style={{
        ...styles,
      }}
      dir="ltr"
    >
      <FullScreen handle={handle}>
        <ReactPlayer
          onSeek={() => {
            if (disableSeeking) {
              debouncedSeek(ref.current?.getCurrentTime());
            }
          }}
          onDuration={(duration) => setDuration(duration)}
          onProgress={(progress) => setProgress(progress.playedSeconds)}
          height={
            handle.active && noControls
              ? "100%"
              : handle.active
              ? "90%"
              : "360px"
          }
          width={handle.active ? "100%" : "640px"}
          ref={ref}
          style={{
            height: "100%",
            width: "100%",
            minWidth: "200px",
            minHeight: "100px",
            maxWidth: "100%",
            maxHeight: type === "audio" ? "80px" : "100%",
            display: type === "audio" ? "none" : isMaximized ? "block" : "none",
            margin: "auto",
            pointerEvents: disableSeeking && !iOS() ? "none" : "auto",
          }}
          volume={volume}
          url={`${src}${
            type === "video" ? `?loop=${loop ? 1 : 0}?playsinline=1` : ""
          }`}
          loop={loop}
          playing={isPlay}
          controls={iOS()}
          onEnded={() => {
            if (isPlaylist) {
              dispatch(playNextTrack({ playlists }));
            } else {
              closeMedia && closeMedia();
            }
          }}
          playsInline
        />
        {(noControls || iOS()) && type === "video" ? null : (
          <Flex
            // marginTop={handle.active ? "0" : "15px"}
            // marginBottom={handle.active ? "10px" : "0"}
            my="10px"
            width={"100%"}
            maxWidth={"100%"}
            height={handle.active ? "10%" : "auto"}
            flexWrap={"wrap"}
            justifyContent={["center"]}
            alignItems={"center"}
            gridGap={["12px", "15px", "20px"]}
            pointerEvents={src === "" ? "none" : "auto"}
          >
            {isPlaylist ? (
              <Button
                maxWidth={"50px"}
                onClick={() => {
                  dispatch(playPreviousTrack({ playlists }));
                }}
              >
                <SkipPreviousIcon />
              </Button>
            ) : null}
            <Button
              maxWidth={"50px"}
              onClick={() => togglePlay && togglePlay()}
            >
              {isPlay ? <PauseIcon /> : <PlayArrowIcon />}
            </Button>
            {isPlaylist ? (
              <Button
                maxWidth={"50px"}
                onClick={() => dispatch(playNextTrack({ playlists }))}
              >
                <SkipNextIcon />
              </Button>
            ) : null}
            <HStack d="flex">
              <Text minWidth="11ch" color={handle.active ? "white" : "black"}>
                {getReadableTime(progress)} / {getReadableTime(duration)}
              </Text>
              <Slider
                aria-label="audio"
                defaultValue={100}
                minWidth={"100px"}
                maxWidth={"120px"}
                value={
                  isNaN(Math.round((progress / duration) * 100))
                    ? 0
                    : Math.round((progress / duration) * 100)
                }
                pointerEvents={disableSeeking ? "none" : "auto"}
                onChange={(e) => {
                  ref.current?.seekTo(e / 100);
                  setProgress((e / 100) * duration);
                }}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb
                  backgroundColor={handle.active ? "white" : "black"}
                />
              </Slider>
            </HStack>
            <HStack d={["none", "none", "flex"]}>
              <Button
                maxWidth={"50px"}
                color={handle.active ? "white" : "black"}
                variant="link"
                onClick={() => {
                  if (volume === 0) {
                    setVolume(1);
                  } else {
                    setVolume(0);
                  }
                }}
              >
                {volume > 0 ? <VolumeUpIcon /> : <VolumeOffIcon />}
              </Button>
              <Slider
                minWidth={"100px"}
                maxWidth={"120px"}
                aria-label="audio"
                defaultValue={100}
                value={volume * 100}
                onChange={(n) => setVolume(n / 100)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb
                  backgroundColor={handle.active ? "white" : "black"}
                />
              </Slider>
            </HStack>
            <HStack>
              {type === "video" && !iOS() ? (
                <Button
                  onClick={() => {
                    if (handle.active) {
                      handle.exit().catch(() => null);
                    } else {
                      handle.enter().catch(() => null);
                    }
                  }}
                >
                  {handle.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </Button>
              ) : null}
            </HStack>
          </Flex>
        )}
      </FullScreen>
    </div>
  );
};
export default MediaPlayer;
