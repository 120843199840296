import {
  Box,
  Button,
  Text,
  useToast,
  IconButton,
  Center,
  Image,
  Flex,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ModalSpinner from "src/components/modalSpinner";
import usePlaylists from "src/views/Playlists/usePlaylists";
import { MdMusicOff, MdMusicNote } from "react-icons/md";

const PlaylistsList = ({ entityName, track, onAdd }) => {
  const {
    playlistsQuery: { data: playlists, isLoading, error },
    addToPlaylistMutation,
  } = usePlaylists();
  const { formatMessage } = useIntl();
  const toast = useToast();

  const addToPlaylist = useCallback(
    async (playlistId, entityName, entityId, trackType) => {
      const res = await addToPlaylistMutation({
        playlistId,
        entityName,
        entityId,
        trackType,
      });
      
      toast({
        title: formatMessage({ id: res?.id ? "saved" : "wentWrong" }),
        status: res?.id ? "success" : "error",
        isClosable: true,
      });
    },
    [addToPlaylistMutation, formatMessage, toast]
  );

  if (error) return null;
  if (isLoading) return <ModalSpinner />;

  return (
    <Box w="full">
      {playlists.length ? (
        playlists.map((playlist) => {
          const index = playlist.tracks.findIndex((item) => {
            if (item.entityName === "waves") {
              return item.tracksIds.includes(track.entityId);
            }
            return `${item.entityName}${item.entityId}` === `${track.entityName}${track.entityId}`;
          });
          const isButtonDisabled = index !== -1;

          return (
            <Box 
              key={playlist.id}
              display="flex" 
              flexDirection={{ base: "column", sm: "row" }}
              gap={2}
              justifyContent="space-between" 
              alignItems={{ base: "flex-start", sm: "center" }}
              p={2}
              borderBottom="1px solid"
              borderColor="gray.200"
            >
              <Text flex="1" mb={{ base: 2, sm: 0 }}>{playlist.name}</Text>

              {entityName === "waves" ? (
                <Flex
                  gap={2} 
                  flexDirection={{ base: "column", md: "row" }}
                  w={{ base: "full", sm: "auto" }}
                >
                  {track.withMusicUrl && (
                    <Button
                      w={{ base: "full", sm: "auto" }}
                      disabled={isButtonDisabled}
                      onClick={() => addToPlaylist(playlist.id, entityName, track.entityId, "music")}
                      size="sm"
                    >
                      {isButtonDisabled ? (
                        <FormattedMessage id="added" />
                      ) : (
                        <Flex align="center">
                          <FormattedMessage id="add" />
                          <Image
                            ml={2}
                            loading="lazy"
                            alt="Wave Icon"
                            src="/wave.png"
                            h="15px"
                            opacity={0.6}
                            _hover={{ opacity: 0.8 }}
                          />
                        </Flex>
                      )}
                    </Button>
                  )}

                  {track.musicFreeUrl && (
                    <Button
                      w={{ base: "full", sm: "auto" }}
                      disabled={isButtonDisabled}
                      onClick={() => addToPlaylist(playlist.id, entityName, track.entityId, "pure")}
                      size="sm"
                    >
                      {isButtonDisabled ? (
                        <FormattedMessage id="added" />
                      ) : (
                        <Flex align="center">
                          <FormattedMessage id="add" />
                          <Image
                            ml={2}
                            loading="lazy"
                            alt="Pure Tone Icon"
                            src="/pure-tone.png"
                            h="15px"
                            opacity={0.6}
                            _hover={{ opacity: 0.8 }}
                          />
                        </Flex>
                      )}
                    </Button>
                  )}
                </Flex>
              ) : (
                <Button
                  w={{ base: "full", sm: "auto" }}
                  disabled={isButtonDisabled}
                  onClick={() => addToPlaylist(playlist.id, entityName, track.entityId)}
                  size="sm"
                >
                  <FormattedMessage id={isButtonDisabled ? "added" : "add"} />
                </Button>
              )}
            </Box>
          );
        })
      ) : (
        <Center h="300px" flexDirection="column">
          <Text textAlign="center">
            <FormattedMessage id="noPlaylists" />
          </Text>
          <IconButton
            className="remove-on-print"
            onClick={onAdd}
            size="lg"
            border="2px solid"
            borderColor="gray.200"
            aria-label="add"
            m="5"
            icon={<AddIcon />}
          />
        </Center>
      )}
    </Box>
  );
};

export default PlaylistsList;