import React from "react";
import { Box, Text, Tag, useToast, Image, Flex } from "@chakra-ui/react";
import ItemCard from "src/components/ItemCard";
import { useIntl } from "react-intl";
import usePlaylists from "./usePlaylists";
import useLocale from "src/providers/useLocale";

const PlaylistItem = ({
  name,
  slug,
  frequency,
  trackType,
  tag,
  url,
  id,
  play,
}) => {
  const {
    deleteTrackMutation,
    playlistsQuery: { refetch: refetchPlaylists },
  } = usePlaylists();

  const { formatMessage } = useIntl();
  const toast = useToast();
  const [locale] = useLocale();

  const deleteTrack = async (trackId) => {
    try {
      await deleteTrackMutation(trackId);
      toast({
        title: formatMessage({ id: "deleted" }),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      await refetchPlaylists();
    } catch (error) {
      toast({
        title: formatMessage({ id: "plan.edit.name.failed" }),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const renderWaveIcon = () => {
    if (tag !== "waves") return null;

    const iconProps = {
      loading: "lazy",
      alt: "Wave Icon",
      height: "15px",
      style: {
        cursor: "pointer",
        opacity: 0.6,
        transition: "opacity 0.2s",
        transform: locale === "ar" ? "scaleX(-1)" : "none",
      },
      _hover: {
        opacity: 0.8,
      },
    };

    if (!trackType) {
      return <Image src="/wave.png" {...iconProps} />;
    }

    return trackType === "music" ? (
      <Image src="/wave.png" {...iconProps} />
    ) : (
      <Image src="/pure-tone.png" {...iconProps} />
    );
  };

  return (
    <Box as="span" flex="1" textAlign="left" w="full">
      <ItemCard
        remove={() => deleteTrack(id)}
        onPlay={play}
        onPause={() => console.log("pause")}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          align={{ base: "flex-start", md: "center" }}
          gap={{ base: 2, md: 3 }}
          w="full"
        >
          <Text
            m={0}
            fontFamily="tajwal"
            fontSize={{ base: "sm", md: "md" }}
            flex="1"
          >
            {name || slug || frequency}
          </Text>

          <Flex 
            align="center" 
            gap={3}
            w={{ base: "full", md: "auto" }}
          >
            <Tag
              size="md"
              borderRadius="full"
              variant="solid"
              textTransform="capitalize"
              whiteSpace="nowrap"
            >
              {formatMessage({ id: tag.replace(/([a-z])([A-Z])/g, "$1 $2") })}
            </Tag>

            <Box 
              ml={2}
              display="flex"
              alignItems="center"
            >
              {renderWaveIcon()}
            </Box>
          </Flex>
        </Flex>
      </ItemCard>
    </Box>
  );
};

export default PlaylistItem;